import React from 'react'

const AdminTIcketSetting = () => {
  return (
    <div className="row">
        <div className="card">
            <h1> Admin Ticket Setting </h1>
        </div>
    </div>
  )
}

export default AdminTIcketSetting