import React from 'react'

const AdminHistoryTab = () => {
    return (
        <>
            <div className="row">
                <div className="col mt-5 text-center">
                    <h6><b>No Data Found</b></h6>
                </div>
            </div>
        </>
    )
}

export default AdminHistoryTab