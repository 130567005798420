import React from 'react'

const AdminAttendenceSetting = () => {
    return (
        <>
            <div className="row">
                <div className="card">
                    <h1>Admin Attendence Setting</h1>
                </div>
            </div>
        </>
    )
}

export default AdminAttendenceSetting