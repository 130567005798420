import React from 'react'

const AdminFinanceSetting = () => {
  return (
    <div className="row">
        <div className="card">
            <h1>Admin Finance Setting</h1>
        </div>
    </div>
  )
}

export default AdminFinanceSetting