import React from 'react'

const AdminAddOrder = () => {
    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <div className="card">
                        <div className="row">
                            <div className="col">
                                <h3>Create Order</h3>
                            </div>
                        </div><hr />
                        <form action="">
                            <div className="row mt-3">
                                <div className="col">
                                    <label htmlFor="">Order Number</label>
                                    <input type="text" name="" id="" className="form-control" />
                                </div>
                                <div className="col">
                                    <label htmlFor="">Client</label>
                                </div>
                                <div className="col">
                                    <label htmlFor="">Billing Address</label>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <label htmlFor="">Shipping Address</label>
                                    <textarea name="" id="" className='form-control'></textarea>
                                </div>
                                <div className="col">
                                    <label htmlFor="">Project</label>
                                    <select name="" id="" className="form-select">
                                        <option value="">--</option>
                                    </select>
                                </div>
                                <div className="col">
                                    <label htmlFor="">Generated By</label>
                                    <select name="" id="" className="form-select">
                                        <option value="">--</option>
                                        <option value="">PSSPL</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <label htmlFor="">Status</label>
                                    <select name="" id="" className="form-select">
                                        <option value="">--</option>
                                        <option value="">Pending</option>
                                        <option value="">On Hold</option>
                                        <option value="">Failed</option>
                                        <option value="">Processing</option>
                                        <option value="">Completed</option>
                                        <option value="">Cancelled</option>
                                    </select>
                                </div>
                                <div className="col"></div>
                                <div className="col"></div>
                                <div className="col"></div>
                            </div><hr />
                            <div className="row mt-3">
                                <div className="col-sm-4">
                                    <div className="input-group">
                                        <select name="" id="" className="form-select">
                                            <option value=""></option>
                                        </select>
                                        <button type="button" className="input-group-text">Add</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <table className='table table-bordered'>
                                        <thead>
                                            <tr>
                                                <th>Description</th>
                                                <th>Quantity</th>
                                                <th>SKU</th>
                                                <th>Unit Price</th>
                                                <th>Tax</th>
                                                <th>Amount</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><input type="text" name="" id="" className="form-control" /></td>
                                                <td><input type="number" name="" id="" className="form-control"min={1} /></td>
                                                <td><input type="text" name="" id="" className="form-control" /></td>
                                                <td><input type="text" name="" id="" className="form-control mt-4" /><b>pcs</b></td>
                                                <td rowSpan={2} className='p-1'><input type="text" name="" id="" className="form-control" /></td>
                                                <td rowSpan={2}><input type="text" name="" id="" className="form-control" /></td>
                                                <td rowSpan={2}><i className='fa fa-times'></i></td>
                                            </tr>
                                            <tr>
                                                    <td colSpan={3}>
                                                        <textarea name="" id="" className='form-control'></textarea>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col"></div>
                                <div className="col">
                                    <table className='table table-bordered'>
                                        <tr >
                                            <th colSpan={2}>Sub Total</th>
                                            <td>0.00</td>
                                        </tr>
                                        <tr>
                                            <td>Discount</td>
                                            <td>
                                                <div className='input-group'>
                                                    <input type="text" name="" id="" className="form-control" />
                                                    <select name="" id="" className='form-group-text form-select'>
                                                        <option value="">%</option>
                                                        <option value="">Amount</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td>0.00</td>
                                        </tr>
                                        <tr>
                                            <th>Tax</th>
                                            <td colSpan={2}>0.00</td>
                                        </tr>
                                        <tr>
                                            <th>Total</th>
                                            <td colSpan={2}>0.00</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <label htmlFor="">Client Note</label>
                                    <textarea name="" id="" className="form-control">

                                    </textarea>
                                </div>
                                <div className="col">

                                </div>
                            </div>
                            <div className="row mt-3 mb-3">
                                <div className="col">
                                    <button type="submit" className='btn btn-white'>Save</button> &nbsp;
                                    <button type="button" className='btn btn-white' data-bs-dismiss="offcanvas">Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminAddOrder